<template>
  <div id="ModalLoginSelecionaEmpresa" class="pa-0">

    <v-card>
      <v-card-title
        v-bind:style="{ 'background-color': COR_PRINCIPAL }"
        class="pa-0"
      >
        <div class="mt-3 ml-4 mb-n1" style="width:100%">
          <p style="color:white; white-space: pre-line; font-size:16px !important;">
            Escolha a empresa que deseja entrar
          </p>                      
        </div>
      </v-card-title>

      <v-card-text class="wrapper pa-0">
        <v-container class="pa-0">
          <v-data-table
            :items="empresas"
            single-select
            show-select
            hide-default-header
            hide-default-footer
            loading-text="Carregando... aguarde..."
            no-data-text="Nenhum Registro Encontrado"
            no-results-text="Nenhum Registro Encontrado">
            <template #item="{ item }">
                <tr :search="search">
                  <td class="td-height" >
                    <!-- :value="item.cod_pessoa == store_ModalNegociacao.pessoa_selecionado.cod_pessoa" -->
                    <v-checkbox
                      v-on:change="seleciona($event, item)"
                      :key="item.cod_empresa"
                      :value="empresaSelecionada.length > 0  && item.cod_empresa == empresaSelecionada[0].cod_empresa"
                      hide-details
                      class="mr-n6"
                      style="margin-top: -50%;"/>
                  </td>
                  <td class="hide-sm py-2" style="width: 99%">
                    <span style="font-size: 15px; display:block; color:#385986 !important;" class="font-weight-bold">
                      {{ item.empresa_nome }}
                    </span>
                    <!-- <span style="font-size: 12px; display:block; color: #808080 !important" class="mb-0">
                      <span style="font-weight: bold" v-if="item.nome_cliente">
                        Cliente: 
                      </span>
                      {{ item.nome_cliente }}
                    </span>
                    <span style="font-size: 12px; color: #808080 !important" class="mb-2 pr-10">
                      <span style="font-weight: bold" v-if="item.obra_endereco || item.endereco_dep">
                        Endereço: 
                      </span>                      
                      {{ item.obra_endereco ? item.obra_endereco : item.endereco_dep}}
                    </span> -->
                  </td> 
                </tr>
              </template>
          </v-data-table>
        </v-container>
        
        <!-- Footer -->
        <v-container>
          <v-footer
            color="transparent"
            elevation="0"
            class="footer justify-center py-0"
          >
            <v-btn
              color="primary"
              class="btn mr-4 caption font-weight-medium"
              text
              @click="cancelar()"
              id="btn-cancelar"
              ref="btn-cancelar"
            >
              Cancelar
            </v-btn>
            <v-btn
              :disabled="!valid"
              :loading="loading"
              @click="selecionaEmpresa(empresaSelecionada)"
              class="btn white--text caption font-weight-medium"
              color="primary accent-4"
            >
              Selecionar
            </v-btn>
          </v-footer>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {
  COR_PRINCIPAL,
  COR_SUBTITULO,
  COR_SECUNDARIA,
} from "../../services/constantes";

export default {
  name: "ModalLoginSelecionaEmpresa",

  props: [
    'empresas'
  ],

  data() {
    return {
      
      loading           : false,
      search            : null,

      COR_PRINCIPAL     : COR_PRINCIPAL,
      COR_SUBTITULO     : COR_SUBTITULO,
      COR_SECUNDARIA    : COR_SECUNDARIA,

      valid               : null,
      empresaSelecionada  : [],

    };
  },

  created() {
  },

  methods: {
    // async buscaObra() {
    //   this.loading = true;
    //   var lo_params = {...lo_params, situacao: "Em Andamento"}
    //   // console.log("Parâmetros de busca: ", lo_params)
    //   let lo_Res 
    //   lo_Res = await this.store_Obra.ObrasGet(lo_params);
    //   this.store_Cotacao.dados = lo_Res;
    //   // console.log("Resultado da busca: ", lo_Res)
    //   this.loading = false;
    // },

    // async buscaDeposito() {
    //   this.loading = true;
    //   //Opções de busca do Filtro
    //   var lo_params = { ...this.store_Deposito.filtro };
    //   let lo_Res 
    //   lo_Res = await this.store_Deposito.DepositosGet(lo_params);
    //   this.store_Cotacao.dados = lo_Res;
    //   this.loading = false;
    //   console.log("Depósito: ", this.store_Cotacao.dados);
    // },

    cancelar() {
      this.$emit('fechar');
    },

    seleciona(value, item) {
      if (value) {
        this.empresaSelecionada = [item];
        this.valid = true;
      }
      else {
        this.empresaSelecionada = [];
        this.valid = false;
      }
    },

    selecionaEmpresa() {
      console.log(this.empresaSelecionada[0]);
      this.$emit('retorno', this.empresaSelecionada[0].cod_empresa)
    }

  }
};
</script>
<style scoped>
.container {
  background: #f2f6f7 !important;
}

.title-page {
  font-family: "Open Sans", sans-serif !important;
}

.search-input {
  margin: 0.8rem 10% 1.2rem 0;
  height: 35px !important;
}

.search-input-longo {
  margin: 0.8rem 10% 1.2rem 0;
  height: 35px !important;
  /* min-width: calc(100% - 15px); */
}

  @media (max-width: 768px) {
    .search-input {
      margin: 0.8rem 10% 1.2rem 0;
    }
    .search-input-longo {
      margin: 0.8rem 10% 1.2rem 0;
      /* min-width: calc(100% - 15px); */
    }
  }

.text-white {
  color: #ffffff;
  letter-spacing: 0px;
}

</style>
